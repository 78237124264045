

<template>
  <div id="mar-container">
    <button-router></button-router>

    <router-view v-slot="{ Component }">
      <!-- <keep-alive> -->
      <component :is="Component"></component>
      <!-- </keep-alive> -->
    </router-view>
  </div>
</template>

<style lang="scss">
#mar-container {
  height: 100%;
  .ar-table {
    margin: 15px 0;
  }
  .ar-table .el-table__cell {
    padding: 9px 0;
  }
  .ar-table th.el-table__cell {
    background-color: var(--search-bg-color);
    padding: 9px 0;
    font-size: 15px;
    color: var(--text-color);
  }
  .ar-table td.el-table__cell div span {
    font-size: 14px;
  }
  .border-icon {
    margin-left: 10px;
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--search-uncheck-bg-color);
    i {
      color: var(--text-third-color);
    }
    &:hover {
      background-color: var(--search-bg-color);
      i {
        color: var(--theme-color) !important;
      }
    }
  }
  // .el-input,
  // .el-select {
  //   width: 100%;
  // }
  .text-center {
    text-align: center;
    line-height: 40px;
    color: #ccc;
  }
  .text-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn {
    width: 80px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    background-color: var(--theme-color);
    color: var(--text-white-color);
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .table-btn {
    width: 76px;
    border-radius: 20px;
    text-align: center;
    min-height: 24px;
    padding: 0;
  }
  .el-checkbox {
    height: auto;
  }
  .search-input {
    .el-input__inner {
      background-color: var(--theme-bg-color) !important;
    }
    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }
  .text-grey {
    color: var(--text-third-color);
  }
  .text-red {
    color: var(--error-color);
  }
  .text-theme {
    color: var(--theme-color);
  }
  .text-black {
    color: var(--text-color);
  }
  .text-orange {
    color: var(--warning-color);
  }
  .font-bold {
    font-weight: bold;
  }
  .text-operation{
    color: var(--theme-color);
    cursor: pointer;
    &:hover {
      font-weight: bold;
      // text-decoration: underline;
    }
  }
}
</style>
